/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M512 101.5c226.7 0 410.5 183.8 410.5 410.5S738.7 922.5 512 922.5 101.5 738.7 101.5 512 285.3 101.5 512 101.5zm-5.4 513.1c-97 0-184.4 57.3-229.2 146.4 63.4 59.9 147.3 93.2 234.6 93.1 83.7.1 164.6-30.6 227.1-86.2-43.5-92.7-132.8-153.3-232.5-153.3zm5.4-444.7C323.1 169.9 169.9 323 169.9 512c0 71.9 22.2 138.6 60 193.6 44.8-73.7 114.7-127.1 195.5-148.7-81.5-47.8-108.8-152.6-61-234.1S517 214 598.5 261.8s108.8 152.6 61 234.1c-15.5 26.4-37.8 48.2-64.5 63 80.8 23.7 150 79.4 193.1 155 42.9-58.5 66-129.3 65.9-201.9.1-188.9-153.1-342.1-342-342.1zm0 136.9c-56.7 0-102.6 45.9-102.6 102.6S455.3 512 512 512s102.6-45.9 102.6-102.6S568.7 306.8 512 306.8z"/>'
  }
})
