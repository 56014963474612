import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueAnalytics from 'vue-analytics'
import baiduAnalytics from 'vue-baidu-analytics'

import ElementUI from 'element-ui'
import './theme/element/index.css'
// import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import SvgIcon from 'vue-svgicon'
import VueAwesomeSwiper from 'vue-awesome-swiper'

import '@/styles/index.css' // global css
import 'swiper/swiper-bundle.css'
import '@/icons' // icon

import router from './router'
import { pr } from '@/utils/preview'
import '@/permission' // permission control
import { getUserName } from '@/utils/auth.js'

Vue.prototype.PR = pr
Vue.prototype.globalGetUsername = getUserName

Vue.config.productionTip = false

Vue.use(ElementUI, VueAwesomeSwiper, { locale })

Vue.use(VueAnalytics, {
  id: 'UA-195213293-1',
  router
})
Vue.use(baiduAnalytics, {
  router: router,
  siteIdList: [
    '30309518ca7b5b05e4cafd431bfb4952'
  ],
  isDebug: false
})
Vue.use(SvgIcon, {
  tagName: 'svg-icon'
})

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
})
// .$mount('#app')
