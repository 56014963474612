/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signup': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M361.9 467.1c0-14.1-8.7-26.6-21.8-31.9-72.7-29.8-120.5-108.2-102.3-194.7 13.7-65 62.5-114.7 127.3-129.3 65.7-14.8 126.9 8.6 166.2 52.2 4.1 4.6 9.8 7.4 16 7.4 18.2 0 28.5-21.6 16.4-35.2-48.6-54.5-124.6-84-206.5-66.4C271.4 87.6 208 155.4 194.1 242c-15.4 96.1 33.4 182.6 110.3 223.6-108.9 38.9-188.8 138.9-197.6 258.9-.9 11.9 8.3 22.1 20.2 22.1h2.2c10.9 0 19.4-8.6 20.2-19.4 8.2-109 85.1-199 187.4-227.1 14.8-4.1 25.1-17.6 25.1-33zM896 810.7h-85.3v-85.3c0-11.7-9.6-21.3-21.3-21.3s-21.3 9.6-21.3 21.3v85.3h-85.3c-11.7 0-21.3 9.6-21.3 21.3 0 11.7 9.6 21.3 21.3 21.3H768v85.3c0 11.7 9.6 21.3 21.3 21.3s21.3-9.6 21.3-21.3v-85.3H896c11.7 0 21.3-9.6 21.3-21.3 0-11.7-9.6-21.3-21.3-21.3zm-46.5-321.8C876.7 343 755 217 610.1 236.7c-97 13.2-173.8 94-182.5 191.5-8.1 89.8 39.5 169.3 112.2 208.1C431.2 675 351.5 774.5 342.2 894c-1 12.5 8.7 23.4 21.3 23.4 10.9 0 20.4-8.2 21.2-19.1 9.7-130.5 117.6-234.1 249.9-236.8 103.5-2.3 196-70.8 214.9-172.6zm-176 126.6c-120 22.9-223.9-80.9-200.9-200.9 12.8-67.1 66.9-121.2 134-134 120-22.9 223.8 80.9 200.9 200.9-12.8 67-67 121.1-134 134z"/>'
  }
})
