/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'forward': {
    width: 200,
    height: 200,
    viewBox: '0 0 3450 1024',
    data: '<path pid="0" d="M89.043 586.203h3272.348c44.522 0 74.203-29.681 74.203-74.203s-29.681-74.203-74.203-74.203H89.043c-44.521 0-74.202 29.681-74.202 74.203s29.68 74.203 74.202 74.203z" _fill="#1B1B1B"/><path pid="1" d="M89.043 586.203c14.841 0 37.102-7.42 51.943-14.84l497.159-430.377c29.681-29.682 29.681-74.203 0-103.885C615.885 0 571.362 0 534.26 29.681L37.1 460.058C7.421 489.739 0 534.261 29.681 563.942c14.84 14.84 37.102 22.26 59.362 22.26z" _fill="#1B1B1B"/><path pid="2" d="M586.203 1016.58c22.26 0 44.522-7.42 59.362-22.261 22.261-29.681 22.261-81.623-14.84-103.884l-497.16-430.377c-29.68-29.681-74.203-22.26-103.884 7.42C0 497.16 7.421 541.681 37.101 571.362l497.16 430.377c14.84 14.84 37.101 14.84 51.942 14.84z" _fill="#1B1B1B"/>'
  }
})
