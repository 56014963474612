/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'test': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M512.5 77C753 77 948 272 948 512.5S753 948 512.5 948 77 753 77 512.5 272 77 512.5 77z" _fill="#d81e06"/>'
  }
})
