/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'diamond': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M511.923 148.917L874.93 511.923 511.923 874.93 148.917 511.923l363.006-363.006m0-86.917L62 511.923l449.923 449.924 449.924-449.924L511.923 62z"/>'
  }
})
