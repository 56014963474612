/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'
import { userApi } from '@/api/usermanagement'
import { sendLogin } from '@/api/login'
import { getUserRole } from '@/utils/auth'
import jwtDecode from 'jwt-decode'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  }
}

const actions = {
  // user login
  login({ commit }, value) {
    // console.log('store login', value)
    const userInfo = value.loginData
    const type = value.type
    // console.log('store userinfo', userInfo)
    // const { account, password } = userInfo
    return new Promise((resolve, reject) => {
      sendLogin(userInfo, type).then(response => {
        // login({ account: account.trim(), password: password }).then(response => {
        const { data } = response
        // console.log('data')
        // console.log(data)
        // const role = jwtDecode(data.token).role
        // console.log('role', role)
        // if (type === 'captcha' && role !== ' ROLE_EXPERT') {
        // }
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response
        console.log('innnnn data')
        console.log(data)
        if (!data) {
          return reject('Verification failed, please Login again.')
        }

        // const { name, avatar } = data

        commit('SET_NAME', data.user.userName)
        // commit('SET_AVATAR', userApi.previewAvatar(data.avatarSrc))
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise(resolve => {
      // removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
    // return new Promise((resolve, reject) => {
    //   logout(state.token).then(() => {
    //     removeToken() // must remove  token  first
    //     resetRouter()
    //     commit('RESET_STATE')
    //     resolve()
    //   }).catch(error => {
    //     reject(error)
    //   })
    // })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

