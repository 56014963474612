/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M128 256h768v85.344H128V256zm0 213.344h768v85.312H128v-85.312zm0 213.312h768V768H128v-85.344z"/>'
  }
})
