const state = {
  judgework_info: 0
}
const mutations = {
  SET_JUDGE_WORK_INFO: (state, payload) => {
    state.judgework_info = payload
  }
}
export default {
  namespaced: true,
  state,
  mutations
}
