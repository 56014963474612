/**
 * 登录/注册 API
 */
import request from '@/utils/request'

// 发送注册验证码
export function getCaptcha(phone) {
  return request({
    url: `/auth/register/captcha/${phone}`,
    method: 'get'
  })
}

// 发送登录验证码
export function getLoginCaptcha(phone) {
  return request({
    url: `/auth/login/captcha/${phone}`,
    method: 'get'
  })
}

export function getForgetCaptcha(phone) {
  return request({
    url: `/auth/forget/captcha/${phone}`,
    method: 'get'
  })
}

export function sendRegister(data) {
  return request({
    url: '/auth/register',
    method: 'post',
    data: data
  })
}

export function sendForget(data) {
  return request({
    url: '/auth/forget',
    method: 'post',
    data: data
  })
}

export function sendLogin(data, type) {
  return request({
    url: '/auth/login?type=' + type,
    method: 'post',
    data: data
  })
}
