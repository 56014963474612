import router from './router'
import store from './store'
import { Message } from 'element-ui'
// 顶部加载进度条效果的js实现
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

// 该文件 在 main.js中被导入，作用在于 全局得未路由设置相应拦截器，拦截登录相关信息并做处理

// 配置 进度条展示参数
NProgress.configure({ showSpinner: false }) // NProgress Configuration

// 设置白名单，可以绕过 拦截器核验
const whiteList = ['/', '/login', '/contestdetail', '/contestnews', '/works'] // no redirect whitelist

// 在每次请求路由前 进行拦截，参数是一个回调函数（进行相关操作）
router.beforeEach(async(to, from, next) => {
  /**
   * 参数说明：
   * to:   要跳转的路由信息
   * from: 跳转前的路由信息
   * next: 函数名，用于在拦截器处理完数据后调用，跳转到指定路由页面
   */

  // start progress bar
  // 开启加载进度条
  NProgress.start()

  // set page title
  // 设置 页面的标题 从to的路由中获得
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  // 从Cookie中获取 token值。如果登陆过那么Cookie中就会存入token
  // 存token的相关操作可见 src/views/login/index.vue/handleLogin()  ------> src/store/modules/user.js/login() 内
  const hasToken = getToken()

  // 判断token是否存在，执行不同的页面跳转逻辑
  if (hasToken) {
    /**
     * 存在token时的跳转逻辑:
     *   本就是跳转 login页面，直接跳转
     *   跳转其他页面，判断 是否存在用户信息，没有就先网络请求获取用户信息（用户信息获取失败则跳转login页面）再跳转；否则直接跳转
     */

    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      // 如果本身要去的页面就是login页面，那么在有了token后说明具有登录的权限了，可以登录到系统并 跳转到 主页
      next({ path: '/' })
      // 跳转成功后 结束进度条加载。
      NProgress.done()
    } else {
      // 如果本身要去的页面不是login页面，而是指定路由页面，那么 根据Vuex存储的用户信息，判断用户信息是否过期
      //  用户信息过期就重新获取信息，否则直接跳转到相应页面

      // store.getters.name 追溯到：src/store/index.js -----> src/store/getters.js/name: state => state.user.name -----> src/store/modules/user.js
      // 获取用户相关信息（登录后用户信息会存储在 Vuex的 state.user对象中，对应文件为src/store/modules/user.js）
      const hasGetUserInfo = store.getters.name
      if (hasGetUserInfo) {
        // 拥有用户信息，那么表明登录过，则直接跳转到指定页面。
        next()
      } else {
        try {
          // get user info
          // 如果用户信息过期，那么就 重新获取登录信息。
          // 流程追溯为：src/store/modules/user.js  Actions中的 getInfo()
          // await store.dispatch('user/getInfo')

          next()
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error.Message || 'Has Error')
          // next(`/login?redirect=${to.path}`)
          next('/')
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/
    /**
     * 无token时的跳转逻辑：
     *    在白名单中的可以直接跳转
     *  不在白名单中的需强制跳转到login界面登录
     *  */
    console.log('topath', to.path)
    // checkPath(to.path)
    // 判断将要跳转的路由是否在白名单中
    // if (whiteList.indexOf(to.path) !== -1) {
    if (checkPath(to.path)) {
      // in the free login whitelist, go directly
      // 表示要跳转的路由 在白名单中，可以直接跳转
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      // 不在白名单中的跳转就 重回定向到login页面强制其先进行登录。
      // 并且通过添加redirect参数，用于登录成功后直接跳转到 我们现在想跳转的页面
      // next(`/login?redirect=${to.path}`)
      next('/login')
      NProgress.done()
    }
  }
})

// 在每次请求后再进行拦截，参数是一个回调函数(进行相关操作)
router.afterEach(() => {
  // finish progress bar
  // 路由跳转结束，所以可以关闭进度条动画
  NProgress.done()
})

function checkPath(path) {
  // console.log('pathhhhhh', path)
  if (path === '/') {
    return true
  } else {
    const len = whiteList.length
    for (let j = 1; j < len; j++) {
      if (path.search(whiteList[j]) !== -1) {
        console.log(whiteList[j])
        return true
      }
    }
  }
  return false
}
