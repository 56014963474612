/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list': {
    width: 200,
    height: 200,
    viewBox: '0 0 1025 1024',
    data: '<path pid="0" d="M358.058 552C399.999 552 434 586 434 627.942v207.116C434 876.999 400 911 358.058 911H150.942C109.001 911 75 877 75 835.058V627.942C75 586.001 109 552 150.942 552zM921 804.6c16.513 0 29.9 13.387 29.9 29.9 0 16.348-13.12 29.632-29.406 29.896l-.494.004H576c-16.513 0-29.9-13.387-29.9-29.9 0-16.348 13.12-29.632 29.406-29.896l.494-.004h345zm0-211c16.513 0 29.9 13.387 29.9 29.9 0 16.348-13.12 29.632-29.406 29.896l-.494.004H576c-16.513 0-29.9-13.387-29.9-29.9 0-16.348 13.12-29.632 29.406-29.896l.494-.004h345zM358.058 113C399.999 113 434 147 434 188.942v207.116C434 437.999 400 472 358.058 472H150.942C109.001 472 75 438 75 396.058V188.942C75 147.001 109 113 150.942 113zM921 368.6c16.513 0 29.9 13.387 29.9 29.9 0 16.348-13.12 29.632-29.406 29.896l-.494.004H576c-16.513 0-29.9-13.387-29.9-29.9 0-16.348 13.12-29.632 29.406-29.896l.494-.004h345zm0-212c16.513 0 29.9 13.387 29.9 29.9 0 16.348-13.12 29.632-29.406 29.896l-.494.004H576c-16.513 0-29.9-13.387-29.9-29.9 0-16.348 13.12-29.632 29.406-29.896l.494-.004h345z" _fill="#333"/>'
  }
})
