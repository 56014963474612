/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M154.408 48.883l-1.24 1.024a54.299 54.299 0 00-1.373.916l-3.072 2.263a36.648 36.648 0 00-7.627 8.327l-.62 1.051-82.512 82.567c-100.46 100.46 15.225 334.82 251.419 570.907l16.923 16.734c228.567 223.016 451.718 329.782 550.184 235.089l87.471-87.471c49.718-47.32 51.712-126.922 3.827-177.233l-10.186-10.24-2.803-2.506-130.668-103.882c-50.122-38.104-122.287-33.065-167.208 13.204l-36.487 36.514-3.503-2.21a727.579 727.579 0 01-222.397-222.505l-3.018-4.823 34.682-38.993-.593.593a127.003 127.003 0 008.677-172.975L334.956 70.306a129.213 129.213 0 00-180.548-21.423zm129.94 61.709L387.18 239.939c15.737 21.666 16.114 49.96 2.129 71.303l-2.048 2.83-75.507 84.938 11.803 20.237A792.253 792.253 0 00609.091 704.89l21.531 12.558 72.38-72.435 3.558-3.395a62.302 62.302 0 0181.57-1.186l124.9 99.301 7.923 7.896a61.09 61.09 0 01-2.156 86.366l-87.578 87.58c-61.818 59.445-266.24-41.5-476.134-251.366l-8.623-8.677C141.07 453.389 42.765 251.715 103.747 190.787l91.89-91.998-1.454 1.078a64.539 64.539 0 0190.166 10.698z"/>'
  }
})
