import request from '@/utils/request'
import jwtDecode from 'jwt-decode'

/**
 * 用户登录 api
 */

// var baseurl = 'https://mockapi.eolinker.com/qRtuQ2h69624648c74b420ed87091b657135128b40de907'

export function login(data) {
  return request({
    // url: '/vue-admin-template/user/login',
    url: '/auth/login',
    method: 'post',
    // baseURL: baseurl,
    data
  })
}

export function getInfo(token) {
  console.log('get user info----------')
  const userInfo = jwtDecode(token)
  // 此时 userInfo 中包含有 用户名 sub, 用户角色 role ...
  console.log(userInfo)
  // 获取用户名
  const userName = userInfo.sub
  // const userName = 'username'
  // 之后再用用户名去获取用户的个人信息
  return request({
    url: '/user/' + userName + '/profile',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/vue-admin-template/user/logout',
    method: 'post'
  })
}
