/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M113.40000000000003 470.3a391.7 391.7 0 10783.4 0 391.7 391.7 0 10-783.4 0z" _fill="#FFF"/><path pid="1" d="M505.1 890C273.7 890 85.4 701.7 85.4 470.3S273.7 50.6 505.1 50.6s419.7 188.3 419.7 419.7S736.5 890 505.1 890zm0-783.4c-200.6 0-363.7 163.2-363.7 363.7S304.5 834 505.1 834s363.7-163.2 363.7-363.7-163.1-363.7-363.7-363.7z"/>'
  }
})
