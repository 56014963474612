/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M640 192a128 128 0 01128 128l-.064 25.6 207.616-124.544a32 32 0 0148.448 27.52v526.912a32 32 0 01-48.448 27.456L767.936 678.336 768 704a128 128 0 01-128 128H128A128 128 0 010 704V320a128 128 0 01128-128h512zm0 64H128a64 64 0 00-64 64v384a64 64 0 0064 64h512a64 64 0 0064-64V320a64 64 0 00-64-64zm320 49.024l-192 115.2v183.552l192 115.2V305.024zM192 320a64 64 0 110 128 64 64 0 010-128z"/>'
  }
})
