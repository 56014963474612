/* eslint-disable */
require('./backward')
require('./bitian')
require('./circle')
require('./close')
require('./consent')
require('./diamond')
require('./download')
require('./file')
require('./filecheck')
require('./filefinish')
require('./filejudge')
require('./forward')
require('./goleft')
require('./goright')
require('./iconfont')
require('./idcode')
require('./list')
require('./menu')
require('./model')
require('./password')
require('./phone')
require('./picture')
require('./signup')
require('./test')
require('./upload')
require('./user')
require('./video')
