/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'idcode': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M512 20.864l392.448 79.36a42.667 42.667 0 0134.219 41.813v577.579a42.667 42.667 0 01-20.566 36.48L512 1002.24 106.027 758.4a42.667 42.667 0 01-20.694-36.608V142.08a42.667 42.667 0 0134.219-41.813L512 20.82zm-.043 86.997l-341.29 69.035v520.747l341.12 204.842L853.333 695.51V176.896l-341.376-69.035zm149.376 190.806A21.333 21.333 0 01682.667 320v42.667A21.333 21.333 0 01661.333 384H554.667v277.333a21.333 21.333 0 01-21.334 21.334h-42.666a21.333 21.333 0 01-21.334-21.334V384H362.667a21.333 21.333 0 01-21.334-21.333V320a21.333 21.333 0 0121.334-21.333h298.666z"/>'
  }
})
