/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'picture': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M924.8 950.4H99.2a95.36 95.36 0 01-95.36-95.36v-704A96 96 0 0199.2 54.4h825.6a96 96 0 0195.36 96v704a95.36 95.36 0 01-95.36 96zm-825.6-832a31.36 31.36 0 00-31.36 32v704a31.36 31.36 0 0031.36 31.36h825.6a31.36 31.36 0 0031.36-31.36v-704a31.36 31.36 0 00-31.36-32z" _fill="#323333"/><path pid="1" d="M146.56 832a30.08 30.08 0 01-22.4-8.96 32 32 0 010-45.44L320 583.68a32 32 0 0145.44 0l192 192a32 32 0 010 44.8 32 32 0 01-45.44 0L343.04 651.52 168.96 825.6a29.44 29.44 0 01-22.4 6.4z" _fill="#323333"/><path pid="2" d="M842.88 819.2a32.64 32.64 0 01-23.04-9.6L602.24 592 478.72 715.52a32 32 0 01-45.44 0 32 32 0 010-44.8l146.56-146.56a32.64 32.64 0 0145.44 0l240 240a32 32 0 010 45.44 32 32 0 01-22.4 9.6zM313.6 455.04a105.6 105.6 0 11105.6-105.6 105.6 105.6 0 01-105.6 105.6zm0-147.2a41.6 41.6 0 1041.6 41.6 41.6 41.6 0 00-41.6-41.6z" _fill="#323333"/>'
  }
})
