/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'password': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M820.16 397.76h-32V328.8a273.12 273.12 0 00-546.24 0v68.96h-25.6a113.76 113.76 0 00-114.08 112.8V874.4a113.6 113.6 0 00114.08 112.8h603.84a113.6 113.6 0 00114.08-112.8V510.56a113.6 113.6 0 00-114.08-112.8zM304 317.12a211.04 211.04 0 11421.92 0v79.04H304zM872.64 880a45.12 45.12 0 01-45.28 44.96H209.28A45.28 45.28 0 01163.84 880V505.44a45.28 45.28 0 0145.44-44.96h618.08a45.12 45.12 0 0145.28 44.96V880z"/><path pid="1" d="M531.68 567.36a61.92 61.92 0 00-34.88 113.76V784a34.88 34.88 0 0069.76 0V681.6A61.76 61.76 0 00584 595.36a64 64 0 00-52.32-27.52z"/>'
  }
})
