/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filecheck': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M672.853 952.19c-173.06 0-307.344-177.368-313.089-184.549a36.623 36.623 0 010-43.086c5.745-7.899 143.619-185.267 313.089-185.267S980.196 718.093 985.94 724.555a36.623 36.623 0 010 43.086c-5.745 7.18-140.028 184.55-313.088 184.55zm-236.97-206.092a359.046 359.046 0 00236.97 134.283A364.073 364.073 0 00910.54 746.098a359.046 359.046 0 00-237.688-135.001 359.046 359.046 0 00-236.97 135.001z" _fill="#333"/><path pid="1" d="M672.853 846.631a101.251 101.251 0 11101.25-100.533 101.251 101.251 0 01-101.25 100.533zm0-129.975a29.442 29.442 0 100 58.166 29.442 29.442 0 100-58.166z" _fill="#333"/><path pid="2" d="M499.074 1024H143.62A112.74 112.74 0 0130.878 911.26V112.74A112.74 112.74 0 01143.618 0h395.67a34.468 34.468 0 0124.415 10.053L820.78 252.77a35.905 35.905 0 0111.49 25.85v193.168a35.905 35.905 0 01-35.905 35.904 35.905 35.905 0 01-35.905-35.904V294.418L524.926 71.809H143.619a40.213 40.213 0 00-40.932 40.932v798.518a40.931 40.931 0 0040.932 40.932h355.455a35.905 35.905 0 0135.905 35.904A36.623 36.623 0 01499.074 1024z" _fill="#333"/><path pid="3" d="M526.362 339.658a35.905 35.905 0 01-35.905-35.905V43.086A35.905 35.905 0 01526.362 7.18a35.905 35.905 0 0135.904 35.905v260.667a35.905 35.905 0 01-35.904 35.905z" _fill="#333"/><path pid="4" d="M783.439 339.658H526.362a35.905 35.905 0 01-35.905-35.905 35.905 35.905 0 0135.905-35.904h257.077a35.905 35.905 0 0135.905 35.904 35.905 35.905 0 01-35.905 35.905z" _fill="#333"/>'
  }
})
