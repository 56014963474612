// import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

const TokenKey = 'contest_token'

export function getToken() {
  const jwt = localStorage.getItem(TokenKey)
  if (!jwt) {
    return null
  }
  const userInfo = jwtDecode(jwt)

  if (userInfo.exp > (Date.now() / 1000)) {
    return jwt
  } else {
    localStorage.clear()
    return null
  }
  // return Cookies.get(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
  // return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
  // return Cookies.remove(TokenKey)
}

export function getUserName() {
  const jwt = localStorage.getItem(TokenKey)
  if (jwt) {
    const userInfo = jwtDecode(jwt)
    return userInfo.sub
  }
}

// 获取用户身份 ROLE_EXPERT
export function getUserRole() {
  const jwt = localStorage.getItem(TokenKey)
  if (jwt) {
    const userInfo = jwtDecode(jwt)
    return userInfo.role
  }
}

export function getAuthorizationHeaderObj() {
  const jwt = getToken()
  if (jwt) {
    return { Authorization: jwt }
  }
}
