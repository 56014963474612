const basePreviewPathTiny = process.env.VUE_APP_PIC_URL_TINY
const basePreviewPath = process.env.VUE_APP_PIC_URL

export const pr = {
  previewTiny(url) {
    if (typeof (url) === 'string' && url.length > 0) {
      return `${basePreviewPathTiny}/${url}`
    }
    return url
  },
  previewOrigin(url) {
    if (typeof (url) === 'string' && url.length > 0) {
      return `${basePreviewPath}/${url}`
    }
    return url
  }
}

