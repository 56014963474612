/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M892.48 138.24a32 32 0 010 45.248L556.416 519.531l328.533 328.533a32 32 0 01-45.269 45.227L511.168 564.8l-327.68 327.68a32 32 0 01-45.248-45.248l327.68-327.701L130.688 184.32a32 32 0 0145.27-45.27l335.189 335.233L847.232 138.24a32 32 0 0145.248 0z"/>'
  }
})
