/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M775.53 960H248.47c-43.6 0-79.06-35.46-79.06-79.06V143.07c0-43.6 35.46-79.06 79.06-79.06h358.39c14.55 0 26.35 11.8 26.35 26.35v158.12c0 14.53 11.82 26.35 26.35 26.35h168.66c14.55 0 26.35 11.8 26.35 26.35v579.76c.01 43.6-35.45 79.06-79.04 79.06zM248.47 116.71c-14.53 0-26.35 11.82-26.35 26.35v737.88c0 14.53 11.82 26.35 26.35 26.35h527.05c14.53 0 26.35-11.82 26.35-26.35v-553.4h-142.3c-43.6 0-79.06-35.46-79.06-79.06V116.71H248.47z" _fill="#333"/><path pid="1" d="M828.23 327.54H659.57c-43.6 0-79.06-35.46-79.06-79.06V90.36c0-10.54 6.28-20.07 15.97-24.22 9.69-4.17 20.92-2.13 28.56 5.14L846.41 282.1a26.358 26.358 0 01-18.18 45.44zM633.22 151.85v96.63c0 14.53 11.82 26.35 26.35 26.35h102.78L633.22 151.85zM512 346.36H331.29c-14.55 0-26.35-11.8-26.35-26.35s11.8-26.35 26.35-26.35H512c14.55 0 26.35 11.8 26.35 26.35s-11.8 26.35-26.35 26.35zm180.7 128H331.29c-14.55 0-26.35-11.8-26.35-26.35s11.8-26.35 26.35-26.35H692.7c14.55 0 26.35 11.8 26.35 26.35.01 14.55-11.79 26.35-26.35 26.35zm0 128H331.29c-14.55 0-26.35-11.8-26.35-26.35s11.8-26.35 26.35-26.35H692.7c14.55 0 26.35 11.8 26.35 26.35s-11.79 26.35-26.35 26.35zm-361.41 128c-14.55 0-26.35-11.8-26.35-26.35s11.8-26.35 26.35-26.35v-.01H512l180.71-.01c14.55 0 26.35 11.8 26.35 26.35v.01c0 14.52-11.75 26.3-26.27 26.35-.03 0-.06-.01-.08 0l-361.42.01z" _fill="#333"/>'
  }
})
