import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    component: () => import('@/views/homepage/index')
  },

  {
    path: '/login',
    redirect: '/login/signin',
    component: () => import('@/views/login/index'),
    children: [{
      path: 'signin',
      component: () => import('@/views/login/signin')
    }, {
      path: 'register',
      component: () => import('@/views/login/register')
    }, {
      path: 'resetpassword',
      component: () => import('@/views/login/resetpassword'),
      name: 'ResetPassword'
    }]
  },

  {
    path: '/contestdetail/:part?',
    component: () => import('@/views/contestdetail/index')
  },

  {
    path: '/contestnews',
    redirect: '/contestnews/newslist',
    component: () => import('@/views/contestnews/index'),
    children: [{
      path: 'newslist',
      component: () => import('@/views/contestnews/newslist')
      // name: 'NewsList',
      // meta: { title: '动态列表' }
      // hidden: true
    }, {
      path: 'newsdetail/:id',
      component: () => import('@/views/contestnews/newsdetail')
    }]

  },

  {
    path: '/works/:id',
    component: () => import('@/views/works/index')
  },

  // 学生端
  // 学生报名
  {
    path: '/enroll/:contestId',
    name: 'EnrollStart',
    redirect: '/enroll/:contestId/notice',
    component: () => import('@/views/enroll/index'),
    children: [{
      path: 'notice',
      name: 'Notice',
      component: () => import('@/views/enroll/notice')
    }, {
      path: 'enroll',
      name: 'Enroll',
      component: () => import('@/views/enroll/enroll')
    }]
  },

  // 个人信息
  {
    path: '/userinfo',
    component: () => import('@/views/userinfo/index')
  },

  // 个人赛事
  {
    path: '/mycontest',
    redirect: '/mycontest/contestinfo',
    component: () => import('@/views/usercontest/index'),
    children: [{
      path: 'contestinfo',
      component: () => import('@/views/usercontest/contestinfo'),
      name: 'ContestInfo'
    }, {
      path: 'enrollinfo/:id',
      component: () => import('@/views/usercontest/enrollinfo'),
      name: 'EnrollInfo'
    }, {
      path: 'enrollcheck/:status/:id/:workId',
      component: () => import('@/views/usercontest/enrollcheck'),
      name: 'EnrollCheck'
    }]
  },

  // 老师端
  {
    path: '/teacher',
    redirect: '/teacher/judgelist',
    component: () => import('@/views/teacher/index'),
    children: [{
      path: 'login',
      component: () => import('@/views/teacher/login'),
      name: 'Login'
    }, {
      path: 'expertinfo',
      component: () => import('@/views/teacher/teacherinfo'),
      name: 'ExpertInfo'
    }, {
      path: 'judgelist',
      component: () => import('@/views/teacher/judgelist'),
      name: 'JudgeList'
    }, {
      path: 'judgedetaillist/:judgeId',
      component: () => import('@/views/teacher/judgedetaillist'),
      name: 'JudgeDetailList'
    }, {
      path: 'judgework',
      component: () => import('@/views/teacher/judgework'),
      name: 'JudgeWork'
    }]
  }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
