/**
 * 和「用户管理」有关的 api 函数
 */

import request from '@/utils/request'

// 根据分页查询用户列表信息
export function getUserList(pageNum, pageSize) {
  return request({
    url: '/user/list',
    method: 'get',
    params: { pageNum, pageSize }
  })
}

// 根据 用户名username 获取用户个人信息
export function getUserInfo(username) {
  // var username = 'username'
  return request({
    url: `/user/${username}/profile`,
    method: 'get'
  })
}

// 管理员：更新用户个人信息（包括删除 is_Locked=true）
export function newUserProfile(username, data) {
  return request({
    url: `/user/${username}/profile`,
    method: 'put',
    data
  })
}

// 增加评审专家：均以数组方式提交
export function addExpert(data) {
  return request({
    url: '/user/create',
    method: 'post',
    data
  })
}

const defaultAvatarUrl = 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fphotocdn.sohu.com%2F20140919%2FImg404451326.jpg&refer=http%3A%2F%2Fphotocdn.sohu.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1614157925&t=70ed4dabb3d8baf2bb017fe9b4a365d3'

export const userApi = {
  /**
   * 获取用户列表
   * @param pageNum
   * @param pageSize
   * @param role ROLE_USER / ROLE_EXPERT / ROLE_ADMIN
   * @param university 学校
   * @param realName 姓名
   * @param type 研究生 / 本科生
   * @returns AxiosPromise {AxiosPromise}
   */
  getUserList: function(pageNum, pageSize, role, university = '', realName = '', type = '') {
    return request({
      url: '/user/list',
      method: 'get',
      params: { pageNum, pageSize, university, realName, type, role }
    })
  },
  /**
   * 获取用户高校列表
   * @param role ROLE_USER / ROLE_EXPERT
   * @returns AxiosPromise {AxiosPromise}
   */
  getUserUniversityList(role) {
    return request({
      url: '/user/universities',
      method: 'get',
      params: { role }
    })
  },
  /**
   * 获取头像上传路径
   * @param username 用户名
   * @returns 头像上传路径 {string}
   */
  uploadAvatarURL: username => `${process.env.VUE_APP_BASE_API}/user/${username}/avatar`,
  /**
   * 获取头像预览路径
   * 如果不存在，则返回默认头像
   * @param avatarSrc 后端返回的头像路径
   * @returns 头像预览路径 {string|string}
   */
  previewAvatar: function(avatarSrc) {
    return avatarSrc ? `${process.env.VUE_APP_BASE_API}/file/preview/avatar/${avatarSrc}` : defaultAvatarUrl
  }
}
