<template>
  <div id="app">
    <meta charset="UTF-8">

    <!-- 顶部 -->
    <div class="header">
      <!-- 长屏 -->
      <div v-if="screenWidth>1200 && notLogin" class="content">
        <el-row>
          <el-col :span="7">
            <div class="img-box">
              <el-image style="width:100%" :src="logo" @click="toHomepage" />
            </div>
          </el-col>
          <el-col :span="11">
            <el-menu
              :default-active="activeIndex"
              class="title"
              mode="horizontal"
              background-color="#1b1b1b"
              text-color="#fff"
              active-text-color="#fff"
              style="display:flex;justify-content:space-around;"
              @select="handleSelect"
            >
              <!-- <el-row>
                <el-col :span="6"> -->
              <el-menu-item index="homepage">
                <div class="title">大赛首页</div>
              </el-menu-item>
              <!-- </el-col> -->
              <!-- <el-col :span="6"> -->
              <el-menu-item index="contestdetail">
                <div class="title">大赛详情</div>
              </el-menu-item>
              <!-- </el-col> -->
              <!-- <el-col :span="6"> -->
              <el-menu-item index="contestnews">
                <div class="title">大赛动态</div>
              </el-menu-item>
              <!-- </el-col> -->
              <!-- <el-col :span="6"> -->
              <el-submenu index="works">
                <template slot="title">
                  <div class="title">历年作品</div>
                </template>
                <div v-for="y in yearlist" :key="y.id">
                  <el-menu-item class="l-sub-menu" :index="y.id+':'+y.title">{{ y.title }}</el-menu-item>
                </div>

              </el-submenu>
              <!-- </el-col>
              </el-row> -->
            </el-menu>
          </el-col>
          <!-- 注册/登录 模块 -->
          <el-col :span="6">
            <el-row v-if="!isLogined">
              <el-col
                :span="3"
                :offset="9"
                style="text-align:center"
              ><span
                class="txt_register"
                @click="goRegister"
              >注册</span></el-col>
              <el-col
                :span="1"
                style="text-align:center;padding:22px 5px"
              >
                <div class="txt_escape" />
              </el-col>
              <el-col
                :span="3"
                style="text-align:center"
              ><span
                class="txt_login"
                @click="goLogin"
              >登录</span>
              </el-col>
            </el-row>
            <el-row v-else>
              <el-col
                :span="4"
                :offset="4"
              >
                <div class="user-icon">
                  <svg-icon
                    name="user"
                    class="icon"
                    color="#fff"
                    width="35"
                    height="35"
                  />
                </div>
              </el-col>
              <el-col
                :span="15"
                :offset="1"
              >
                <el-dropdown
                  style="line-height:60px"
                  trigger="click"
                  @command="chooseDropdownMenu"
                >
                  <span class="el-dropdown-link">
                    我的账号<i class="el-icon-arrow-down el-icon--right" />
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu style="font-size:10px">
                      <el-dropdown-item divided command="info">{{ menulist[0] }}</el-dropdown-item>
                      <el-dropdown-item divided command="mycontest">{{ menulist[1] }}</el-dropdown-item>
                      <el-dropdown-item divided command="logout">{{ menulist[2] }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

      </div>

      <!-- 窄屏 -->
      <div v-else-if="screenWidth<1200 && notLogin">
        <!-- icon -->
        <div class="n-img">
          <el-image style="width:100%" :src="logo" @click="toHomepage" />
        </div>

        <!-- 界面选择 -->
        <div class="n-pg-menu" @click="showMenu">
          <svg-icon v-if="!isMenuShow" name="menu" class="icon" color="#eee" width="30" height="30" />
          <svg-icon v-else name="close" class="close" style="margin-top: 3px" color="#eee" width="25" height="25" />
        </div>
      </div>

      <!-- 长屏登录 -->
      <div v-else-if="screenWidth>=1200 && (!notLogin)">
        <!-- icon -->
        <div class="lg-img">
          <el-image style="width:100%" :src="logo" />
        </div>

        <!-- 界面选择 -->
        <div class="lg-back" @click="toHomepage">
          <span style="border-bottom: 1px solid #b5ff10">回到首页</span>
        </div>
      </div>

      <!-- 窄屏登录 -->
      <div v-else>
        <!-- icon -->
        <div class="n-lg-img">
          <el-image style="width:100%" :src="logo" />
        </div>

        <!-- 界面选择 -->
        <div class="n-lg-back" @click="toHomepage">
          <span style="border-bottom: 1px solid #b5ff10">回到首页</span>
        </div>
      </div>

    </div>

    <!-- 菜单显示 -->
    <div v-if="screenWidth<=1200 && isMenuShow" class="n-menu">
      <div v-for="(t, i) in title" :key="t">
        <div v-if="i<3" class="n-pg-select border-limit" @click="gotoPage(t)">
          <div class="n-pg-title">{{ t }}</div>
        </div>
        <!-- 历年作品 -->
        <div v-else class="n-pg-select" @click="showMore">
          <div class="n-pg-title">{{ t }}</div>
          <div :class="[isYearOpen ? 'more-box-click': '', 'more-box']">
            <i style="font-size: 20px;" class="el-icon-arrow-right" />
          </div>
        </div>
      </div>
      <!-- 年 -->
      <div v-if="isYearOpen">
        <div v-for="(year, i) in yearlist" :key="year.id">
          <div v-if="i<3" class="n-year-select border-limit" @click="gotoPage('历年作品', year.id, year.title)">
            <div class="n-year-title">{{ year.title }}</div>
          </div>
          <div v-else class="n-year-select" @click="gotoPage('历年作品', year.id, year.title)">
            <div class="n-year-title">{{ year.title }}</div>
          </div>
        </div>
      </div>

    </div>

    <!-- 页面 -->
    <router-view />
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
import '@/icons/user'
import '@/icons/menu'
import '@/icons/close'
import { fetchResource, getAllExhibitionIsShow } from '@/api/homepage'
import { getToken, removeToken, getUserRole } from '@/utils/auth'

const { detect } = require('detect-browser')

export default {
  name: 'App',
  data() {
    return {
      activeIndex: '1',
      // 网页窗口大小
      screenWidth: '',
      screenHeight: '',

      // flowChart: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimage.3001.net%2Fimages%2F20200410%2F1586487739_5e8fe1bbbd582.png&refer=http%3A%2F%2Fimage.3001.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1618385534&t=5405e390854da3ae805e27e61bb84ef6',
      logo: require('./assets/logo.png'),
      // banner: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.90sjimg.com%2Fdesign%2F00%2F64%2F43%2F50%2F574183ab9e47f.jpg&refer=http%3A%2F%2Fpic.90sjimg.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1618385589&t=eac30baa2f30d9c854f9cb794611c5b6',
      title: ['大赛首页', '大赛详情', '大赛动态', '历年作品'],
      yearlist: [{}],

      // 窄屏设置
      isMenuShow: false,
      isYearOpen: false,

      // 登录
      notLogin: false,

      // 是否登录成功
      isLogined: false,
      userRole: '',

      // 根据用户身份显示下拉菜单
      menulist: ['个人信息', '我的赛事', '退出登录']
    }
  },
  watch: {
    $route(to, from) {
      if (to.path.includes('/login')) {
        this.notLogin = false
      } else {
        this.notLogin = true
      }
      if (from.path.includes('/login')) {
        this.checkUserStatus()
      }
      // console.log(this.notLogin);
    }
  },
  created() {

  },
  mounted() {
    const browser = detect()
    console.log('browser', browser)
    this.judgeBrowser(browser)
    // console.log('logggg===============')
    // console.log(this.isLogin)
    // 判断是否处在登录界面
    // console.log('in')
    var path = window.location.href
    var patt = /login/
    var n = path.search(patt)
    if (n >= 0) {
      this.notLogin = false
    } else {
      this.notLogin = true
    }

    // 判断用户是否已经登录，即localStorage里是否保存有token
    this.checkUserStatus()

    // 实时监听窗口大小的变化
    this.screenWidth = document.body.clientWidth
    this.screenHeight = document.body.clientHeight
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
        this.screenHeight = document.body.clientHeight
        // console.log('width ' + this.screenWidth)
        // console.log('height ' + this.screenHeight)
      })()
    }
    this.getAllExhibitionShowList()
    this.fetchResource()
    // console.log("kkkkkk")
  },
  methods: {
    // 判断所使用的浏览器
    judgeBrowser(browser) {
      // alert(JSON.stringify(browser))
      let os = browser.os
      os = os.toLowerCase()
      // console.log('os', os)
      // 判断是否是Mac OS、Linux、Windows系统
      if (os.indexOf('mac') !== -1 || os.indexOf('linux') !== -1 || os.indexOf('windows') !== -1) {
        switch (browser && browser.name) {
          case 'chrome':
          case 'safari':
          case 'edge-chromium':
          case 'chromium-webview':
          case 'ios-webview':
          case 'ios':
            // console.log('supported')
            break

          case 'firefox':
            // console.log('ff', browser.version)
            if (browser.version < '60.0.0') {
              this.$alert('目前所用浏览器版本过低，推荐使用Chrome、Safari、新版Firefox、Edge浏览器', '提示')
            }
            break

          default:
            // console.log('not supported')
            this.$alert('目前所用浏览器版本过低，推荐使用Chrome、Safari、新版Firefox、Edge浏览器', '提示')
        }
      }
    },
    // 判断用户登录状态
    checkUserStatus() {
      const token = getToken()
      if (token) {
        this.isLogined = true
        this.userRole = getUserRole()
        if (this.userRole === 'ROLE_EXPERT') {
          this.menulist[1] = '评审列表'
        } else {
          this.menulist[1] = '我的赛事'
        }
      } else {
        this.isLogined = false
      }
    },
    // 登录后选择我的账号子菜单
    chooseDropdownMenu(command) {
      // console.log(command)
      // 进入个人信息界面
      if (this.userRole !== 'ROLE_EXPERT') {
        if (command === 'info') {
          this.$router.push('/userinfo')
        }
        // 进入我的赛事界面
        if (command === 'mycontest') {
          this.$router.push('/mycontest')
        }
      } else {
        if (command === 'info') {
          this.$router.push('/teacher/expertinfo')
        }
        // 进入我的赛事界面
        if (command === 'mycontest') {
          this.$router.push('/teacher/judgelist')
        }
      }

      // 进入退出登录界面
      if (command === 'logout') {
        removeToken()
        localStorage.clear()
        this.$router.push('/')
        this.isLogined = false
      }
    },
    // 小屏时点击标题栏进行跳转
    gotoPage(name, id, title) {
      // console.log(name)
      var pageName = ''
      if (name === '大赛首页') {
        pageName = ''
      } else if (name === '大赛详情') {
        pageName = 'contestdetail'
      } else if (name === '大赛动态') {
        pageName = 'contestnews'
      } else if (name === '历年作品') {
        pageName = 'works/' + id + ':' + title
      }
      // console.log(pageName)
      // console.log(pageName)
      this.$router.push('/' + pageName)
      this.isMenuShow = false
      this.isYearOpen = false
    },
    // 长屏——选中菜单
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
      console.log(this.yearlist)
      if (key === 'homepage') {
        // console.log('I am homepage')
        this.$router.push('/')
      } else if (keyPath[0] === 'works') {
        this.$router.push('/' + keyPath[0] + '/' + keyPath[1])
      } else {
        this.$router.push('/' + keyPath[0])
      }
    },
    // 窄屏显示菜单
    showMenu() {
      this.isMenuShow = !this.isMenuShow
    },
    // 窄屏显示年份
    showMore() {
      // console.log(111)
      this.isYearOpen = !this.isYearOpen
    },
    // 用户注册
    goRegister() {
      this.notLogin = false
      this.$router.push('/login/register')
    },
    // 用户登录
    goLogin() {
      this.notLogin = false
      this.$router.push('/login')
    },
    // 回到首页
    toHomepage() {
      this.notLogin = true
      this.$router.push('/')
    },
    getAllExhibitionShowList() {
      getAllExhibitionIsShow().then(res => {
        this.yearlist = res.data.reverse()
        // let yList = res.data.map(item => {
        //   return item.title.slice(0, 4)
        // })
        // this.yearlist = yList
        // this.yearactive = this.yearlist[0]
        // console.log(yList)
      })
    },
    previewUrl(url) {
      return `${process.env.VUE_APP_BASE_API}/file/preview/${url}`
    },
    fetchResource() {
      fetchResource().then(res => {
        const resource = res.data
        resource.banner = this.previewUrl(resource.banner)
        resource.logo = this.previewUrl(resource.logo)
        resource.flowChart = this.previewUrl(resource.flowChart)
        // console.log("resource")
        // console.log(resource)
        this.logo = resource.logo
      })
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

.header {
    position: fixed;
    width: 100vw;
    height: 60px;
    background-color: var(--background-color);
    color: white;
    z-index: 2;
}

.n-menu {
  position: fixed;
  width: 100%;
  top: 60px;
  background-color: var(--background-color);
  color: white;
  z-index: 2;
  /* border: 1px solid white; */
}

.content {
  width: 85%;
  margin: auto;
  /* border: 1px solid white; */
  height: 60px;
}

/* 长屏幕 */

.img-box {
  text-align: left;
  padding-top: 8px;
  width: 200px;
}

.el-menu {
  border: none !important;
}

.l-sub-menu {
  border: 1px solid white;
  background-color: white !important;
  /* border-radius: 10px !important; */
  color: black !important;
  height: 50px !important;
  line-height: 50px !important;
  font-size: 15px !important;
  padding-left: 20px !important;
  /* border: none !important; */
}

.l-sub-menu:hover {
  background-color: var(--background-light-color) !important;
}

.title {
  line-height: 60px;
  font-weight: 800;
  font-size: 16px;
  /* border: 1px solid var(--background-color); */
  text-align: center;
}

.title:hover {
  cursor: pointer;
}

.login {
  float: right;
  width: 100px;
  height: 60px;
  /* text-align: right; */
  /* border: 1px solid white; */
}

.user-icon {
  display: inline-block;
  width: 50px;
  text-align: right;
  margin-top:13px;
  /* border: 1px solid white; */
}

.login-text {
  display: inline-block;
  text-align: center;
  line-height: 60px;
  width: 50px;
  font-weight: 800;
  font-size:16px;
  /* border: 1px solid white; */
}

.login:hover {
  cursor: pointer;
}

.txt_register {
  line-height: 60px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}
.txt_escape {
  height: 16px;
  border-right: 1px solid white;
}
.txt_login {
  line-height: 60px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.el-dropdown-link {
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

/* 窄屏幕 */

.n-img {
  float: left;
  margin-left: 15px;
  margin-top: 8px;
}

.n-pg-menu {
  float: right;
  margin-right: 15px;
  margin-top: 15px;
}

.n-pg-menu:hover {
  cursor: pointer;
}

.n-pg-select {
  width: 100%;
  /* border: 1px solid white; */
  /* border-bottom: 0.5px solid rgb(48, 47, 47); */
  height: 60px;
  line-height: 60px;
}

.n-pg-select:hover {
  cursor: pointer;
}

.n-year-select {
  width: 100%;
  height: 60px;
  line-height: 60px;
}

.n-year-select:hover {
  cursor: pointer;
}

.n-pg-title {
  display: inline-block;
  font-weight: 800;
  font-size: 18px;
  /* border: 1px solid white; */
  margin-left: 20px;
}

.n-year-title {
  display: inline-block;
  /* font-weight: 800; */
  font-size: 18px;
  /* border: 1px solid white; */
  margin-left: 40px;
}

.border-limit {
  border-bottom: 0.5px solid rgb(48, 47, 47);
}

.more-box {
  float: right;
  margin-right: 15px;
  /* border: 1px solid white;  */
  /* background-color: rgb(204, 203, 203); */
  transition: transform 0.5s;
  -moz-transition: -moz-transform 0.5s; /* Firefox 4 */
  -webkit-transition: -webkit-transform 0.5s; /* Safari 和 Chrome */
  -o-transition: -o-transform 0.5s;
}

.more-box-click {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

/* 登录 */
.lg-img {
  float: left;
  margin-left: 260px;
  margin-top: 8px;
}

.lg-back {
  float: right;
  margin-right: 260px;
  line-height: 60px;
  color: #b5ff10;
  font-weight: 800;
  font-size:14px;
}

.lg-back:hover {
  cursor: pointer;
}

/* 窄屏 */
.n-lg-img {
  float: left;
  margin-left: 20px;
  margin-top: 8px;
}

.n-lg-back {
  float: right;
  margin-right: 20px;
  line-height: 60px;
  color: #b5ff10;
  font-weight: 800;
  font-size:14px;
}

.n-lg-back:hover {
  cursor: pointer;
}
</style>

<style>

.el-submenu>.el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

.el-menu--horizontal .el-menu--popup {
  padding: 0px;
}

.el-menu-item.is-active {
  border-bottom-color: transparent !important;
}

.el-submenu__title{
  border-bottom-color: transparent !important;
}
</style>

<style scoped>
/* 调整下拉菜单整体样式 */
.el-dropdown-menu {
  padding: 0px;
  margin: 0px;
  border-radius:0 0 4px 4px;
}
/* 调整下拉菜单弹出框位置的 */
.el-popper[x-placement^=bottom] {
    margin-top: 5px;

}
/* 消去下拉菜单框箭头的 */
.el-popper[x-placement^=bottom] /deep/ .popper__arrow {
  display: none;
}

/* 下划线样式 */
.el-dropdown-menu__item--divided{
  margin-top: 0px;
  margin-left: 5px;
  margin-right: 5px;
}
.el-dropdown-menu__item--divided:nth-child(1){
  border-top: none;
}
/* 菜单项样式 */
.el-dropdown-menu__item /deep/{
  color: #444547;
}

</style>
