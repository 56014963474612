/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bitian': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M512 691.2c-10.6 0-19.2-8.6-19.2-19.2V352c0-10.6 8.6-19.2 19.2-19.2s19.2 8.6 19.2 19.2v320c0 10.6-8.6 19.2-19.2 19.2z" _fill="#131414"/><path pid="1" d="M373.5 611.2c-6.7 0-13.1-3.5-16.7-9.6-5.3-9.2-2.2-20.9 7-26.2l277.1-160c9.2-5.3 20.9-2.2 26.2 7s2.2 20.9-7 26.2L383 608.6c-3 1.8-6.3 2.6-9.5 2.6z" _fill="#131414"/><path pid="2" d="M650.5 611.2c-3.2 0-6.5-.8-9.6-2.6l-277.1-160c-9.2-5.3-12.3-17-7-26.2s16.9-12.3 26.2-7l277.1 160c9.2 5.3 12.3 17 7 26.2-3.5 6.1-9.9 9.6-16.6 9.6z" _fill="#131414"/>'
  }
})
