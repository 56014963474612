/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filefinish': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M781.2 667.6h-62.3v-466c0-26.7-11.2-46.6-33.5-59.3-8.7-5-18.2-6.9-28.1-6.9-157.2 0-314.3-.1-471.5.1-30.4 0-54.5 21-60.4 51.8-.8 4-1.1 8.2-1.1 12.4-.1 211.5-.1 423 0 634.5 0 35.2 25.6 63 59 64.3 1.9.1 3.7.1 5.6.1h345.6V964H183.8c-58.6-.1-110.4-46.3-119.8-107-1.2-7.8-1.9-15.8-1.9-23.7-.1-210.9-.1-421.9-.1-632.8C62 135.8 105.2 82 165.7 71.6c6-1 12.2-1.5 18.3-1.6h475.1c59.1 0 111 46.6 120.2 107.8 1.2 7.7 1.8 15.6 1.8 23.4.1 154.1.1 308.1.1 462.2v4.2z"/><path pid="1" d="M572.4 767.6c16.3-13.7 32.3-27.2 48.6-40.9 22.6 29.7 45.1 59.2 67.8 89 79.6-69.7 158.8-139.1 238.4-208.8 13.3 16.8 26.5 33.4 39.9 50.3-95.8 83.8-191.4 167.5-287.3 251.5-35.7-47-71.4-93.9-107.4-141.1zm46-513.3v65.1H224.8v-65.1h393.6zm.1 153.5v64.8H224.9v-64.8h393.6zm-.1 153.5v64.8H224.7v-64.8h393.7zM465.3 714.6v65.1H224.8v-65.1h240.5z"/>'
  }
})
