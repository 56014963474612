/**
 * 和「首页管理」相关的 api 函数
 */
import request from '@/utils/request'

// 上传比赛详情
export function uploadContestDetail(content, author) {
  return request({
    url: '/homePage/detail',
    method: 'put',
    data: { 'detail': content, 'author': author }
  })
}
// 获得主页各种资源Banner、Logo之类的
export function fetchResource() {
  return request({
    url: '/homePage/load',
    method: 'get'
  })
}
// 获得比赛详情
export function retrieveContestDetail() {
  return request({
    url: '/homePage/detail',
    method: 'get'
  })
}
// 获得比赛详情
export function retrieveContestNotice() {
  return request({
    url: '/homePage/notice',
    method: 'get'
  })
}
// 获得往届赛事列表
export function retrieveExhibitionList(pageNum, pageSize) {
  return request({
    url: '/exhibition',
    method: 'get',
    params: { pageNum, pageSize }
  })
}
// 删除往届赛事
export function deleteExhibition(list) {
  return request({
    url: '/exhibition',
    method: 'delete',
    data: list
  })
}
// 按名称来搜索
export function fuzzyQueryExhibitionPaged(pageNum, pageSize, name) {
  return request({
    url: '/exhibition/query',
    method: 'get',
    params: { pageNum, pageSize, name }
  })
}
export function creatDefaultExhibition() {
  return request({
    url: '/exhibition',
    method: 'post'
  })
}

export function getNewsList(pageNum, pageSize) {
  return request({
    url: '/homePage/news',
    method: 'get',
    params: { pageNum, pageSize }
  })
}

export function deleteNews(newsList) {
  return request({
    url: '/homePage/news',
    method: 'delete',
    data: newsList
  })
}

export function fuzzyQueryNewsPaged(name, pageNum, pageSize) {
  return request({
    url: '/homePage/filterNews',
    method: 'get',
    params: { name, pageSize, pageNum }
  })
}

export function updateNews(form) {
  return request({
    url: '/homePage/news',
    method: 'put',
    data: form
  })
}

export function createNews(form) {
  return request({
    url: '/homePage/news',
    method: 'post',
    data: form
  })
}

export function retrieveNews(id) {
  return request({
    url: '/homePage/news/' + id,
    method: 'get'
  })
}

export function retrieveExhibitionInfo(id) {
  return request({
    url: '/exhibition/' + id + '/info',
    method: 'get'
  })
}

export function retrieveWorksByPrizeName(id, prizeName, pageSize, pageNum) {
  return request({
    url: '/exhibition/' + id + '/works',
    method: 'get',
    params: { pageNum, pageSize, prizeName }
  })
}

export function getAllExhibitionIsShow() {
  return request({
    url: '/exhibition/show',
    method: 'get'
  })
}

export function fetchExhibitionWorks(exhibitionId) {
  return request({
    url: `/exhibition/${exhibitionId}/random_works`,
    method: 'get'
  })
}
export function retrieveExhibitionWork(id, needHide) {
  return request({
    url: '/exhibition/simpleWork/' + id,
    params: { needHide },
    method: 'get'
  })
}
