/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'backward': {
    width: 200,
    height: 200,
    viewBox: '0 0 3450 1024',
    data: '<path pid="0" d="M3361.391 586.203H89.043c-44.521 0-74.202-29.681-74.202-74.203s29.68-74.203 74.202-74.203h3272.348c44.522 0 74.203 29.681 74.203 74.203s-37.101 74.203-74.203 74.203z" _fill="#1B1B1B"/><path pid="1" d="M3361.391 586.203c-14.84 0-37.101-7.42-51.942-14.84l-497.16-430.377c-29.68-29.682-37.1-74.203-7.42-103.885 29.682-29.68 74.203-37.101 103.885-7.42l497.159 430.377c29.681 29.681 37.101 74.203 7.42 103.884-14.84 14.84-29.68 22.26-51.942 22.26z" _fill="#1B1B1B"/><path pid="2" d="M2864.232 1016.58c-22.261 0-44.522-7.42-59.362-22.261-29.682-29.681-22.261-74.203 7.42-103.884l497.16-430.377c29.68-29.681 74.202-22.26 103.883 7.42 29.681 29.681 22.261 74.203-7.42 103.884l-497.16 430.377c-7.42 14.84-29.68 14.84-44.521 14.84z" _fill="#1B1B1B"/>'
  }
})
